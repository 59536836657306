<template>
  <div>
    <div class="header">
      <div class="container py-5">
        <div class="row">
          <div class="col-md-7">
            <h1>{{ $t("buildBookingEngine.title") }}</h1>
            <p>
              {{ $t("buildBookingEngine.description") }}
            </p>
            <a href="#" class="btn btn-light text-main px-5">{{
              $t("buildBookingEngine.bookDemo")
            }}</a>
          </div>
          <div class="col-md-5 my-5 my-md-0">
            <div class="bg-light rounded p-3">
              <h5 class="text-center text-main font-weight-bold py-2">
                {{ $t("buildBookingEngine.scheduleDemo") }}
              </h5>
              <div class="row">
                <div class="col-md-6 pr-md-1 mb-3">
                  <ValidatedInput
                    :name="$t('buildBookingEngine.firstName')"
                    :placeholder="`${$t('buildBookingEngine.firstName')}*`"
                    rules="required"
                    border
                  />
                </div>
                <div class="col-md-6 pl-md-1 mb-3">
                  <ValidatedInput
                    :name="$t('buildBookingEngine.lastName')"
                    :placeholder="`${$t('buildBookingEngine.lastName')}*`"
                    rules="required"
                    border
                  />
                </div>
                <div class="col-md-12 mb-3">
                  <ValidatedInput
                    :name="$t('buildBookingEngine.email')"
                    :placeholder="`${$t('buildBookingEngine.email')}*`"
                    type="email"
                    rules="required"
                    border
                  />
                </div>
                <div class="col-md-12 mb-3">
                  <ValidatedInput
                    :name="$t('buildBookingEngine.phoneNumber')"
                    :placeholder="`${$t('buildBookingEngine.phoneNumber')}*`"
                    rules="required"
                    border
                  />
                </div>
                <div class="col-md-6 pr-md-1 mb-3">
                  <ValidatedInput
                    :name="$t('buildBookingEngine.propertyName')"
                    :placeholder="`${$t('buildBookingEngine.propertyName')}*`"
                    rules="required"
                    border
                  />
                </div>
                <div class="col-md-6 pl-md-1 mb-3">
                  <ValidatedInput
                    :name="$t('buildBookingEngine.noOfRooms')"
                    :placeholder="`${$t('buildBookingEngine.noOfRooms')}*`"
                    rules="required"
                    border
                  />
                </div>
                <div class="col-md-12 mb-3">
                  <ValidatedInput
                    :name="$t('buildBookingEngine.country')"
                    :placeholder="`${$t('buildBookingEngine.country')}*`"
                    type="country"
                    rules="required"
                    border
                  />
                </div>
                <div class="col-md-12 mb-3">
                  <ValidatedInput
                    :name="$t('buildBookingEngine.howDidYouDiscover')"
                    :placeholder="`${$t(
                      'buildBookingEngine.howDidYouDiscover'
                    )}*`"
                    rules="required"
                    border
                  />
                </div>
              </div>
              <button class="btn btn-block btn-main">
                {{ $t("buildBookingEngine.submit") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="container content py-5">
      <div class="row">
        <div class="col-md-7">
          <div class="">
            <h1>
              {{ $t("buildBookingEngine.hotelToChannel") }}
            </h1>
            <p>
              {{ $t("buildBookingEngine.hotelToChannelContent") }}
            </p>
          </div>
          <div
            class="accordion"
            :class="{ active: questionId === i }"
            v-for="i in 3"
            :key="i"
          >
            <div class="question py-1 border-bottom" @click="toggleQuestion(i)">
              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <p>{{ $t(`buildBookingEngine.faqQuestion${i}`) }}</p>
                </div>
                <div>
                  <img src="../assets/icons/accordion.svg" alt="arrow" />
                </div>
              </div>
            </div>
            <div class="answer mt-3" v-show="questionId === i">
              <p>{{ $t(`buildBookingEngine.faqAnswer${i}`) }}</p>
            </div>
          </div>
        </div>
        <div class="col-md-5 mt-auto">
          <img
            class="img-fluid"
            src="../assets/images/build-engine.png"
            alt="Booking Engine"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "BuildBookingEngine",
  data() {
    return {
      questionId: null,
    };
  },
  methods: {
    toggleQuestion(id) {
      if (this.questionId === id) {
        this.questionId = null;
      } else {
        this.questionId = id;
      }
    },
  },
};
</script>

<style scoped>
.header {
  color: #fff;
  background: #ff762b;
  color: white;
}

.header h1 {
  font-weight: bold;
  font-size: 40px;
  text-transform: uppercase;
}

.header p {
  line-height: 35px;
}

.accordion p {
  line-height: 25px !important;
}

.accordion img {
  transition: all 0.5s;
}

.accordion .answer {
  display: block;
}

.accordion.active img {
  transform: rotate(180deg);
  transition: all 0.3s;
}

.accordion.active .answer {
  display: block;
}

.content {
  margin-top: 50px;
  margin-bottom: 50px;
}
.content h1 {
  font-size: 35px;
  text-transform: uppercase;
  font-weight: bolder;
  color: #444444;
}
.content h3 {
  font-size: 28px;
  color: #444444;
}
.content p {
  color: #969696;
  line-height: 35px;
}

.contact-card {
  line-height: 30px;
}
@media only screen and (max-width: 500px) {
  .content {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .content h1 {
    font-size: 28px;
  }

  .content h3 {
    font-size: 25px;
  }
}
</style>
